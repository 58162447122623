var opts = {
  lines: 9 
, length: 15 
, width: 7 
, radius: 15 
, scale: 1 
, corners: 1 
, color: '#FFF' 
, opacity: 0.25 
, rotate: 0 
, direction: 1 
, speed: 1 
, trail: 60 
, fps: 20 
, zIndex: 2e9 
, className: 'spinner'
, top: '50%' 
, left: '50%' 
, shadow: false 
, hwaccel: false 
, position: 'absolute'
}
var target = document.getElementById('spinner-1')
var spinner = new Spinner(opts).spin(target);

setTimeout(function(){
	$("#spinner-1").fadeOut('500');
	$("#overlay").fadeOut('500')
}, 150);


$(document).ready(function(){
	$("main").mCustomScrollbar({
	    axis:"x"
	});

	$(".fa-search").on('click', function(){
		$(".form-group").slideToggle();
	});
});

var app = angular.module('periodicTable', ['ngAnimate']);

app.filter('setDecimal', function ($filter) {
    return function (input, places) {
        if (isNaN(input)) return input;
        var factor = "1" + Array(+(places > 0 && places + 1)).join("0");
        return Math.round(input * factor) / factor;
    };
});

app.factory('MainElements', ['$http', function($http){
  return {
    get: function(callback){
      $http.get('../data/periodic.json').success(function(data){
        callback(data);
      });
    }
  }
}]);

app.factory('RestElements', ['$http', function($http){
  return {
    get: function(callback){
      $http.get('../data/rest.json').success(function(data){
        callback(data);
      })
    }
  }
}]);

app.controller('MainController', function($scope, $filter, $http, MainElements, RestElements, $timeout){

	$scope.isVisible = false;
	$scope.innerContainer = "";
	$scope.sortByType = "";
	MainElements.get(function(response){
		$scope.elements = response;              
	});		

	RestElements.get(function(response){
		$scope.restElements = response;                
	});			

		var elPlaces = {
			1: 'f1 brd top left ',
			2: 'f2 top right ',
			3: 'f3 left ',
			4: 'brd top ',
			5: 'f5 top ',
			6: 'f5 top ',
			7: 'f5 top ',
			8: 'f5 top ',
			9: 'f5 top ',
			10: 'f5 right ',
			11: 'f3 left ',
			12: 'brd ',
			13: 'f5 ',
			14: 'f5 ',
			15: 'f5 ',
			16: 'f5 ',
			17: 'f5 ',
			18: 'f5 right ',
			19: 'f3 left ',
			21: 'top ',
			22: 'top ',
			23: 'top ',
			24: 'top ',
			25: 'top ',
			26: 'top ',
			27: 'top ',
			28: 'top ',
			29: 'top ',
			30: 'top ',
			36: 'right ',
			37: 'left ',
			54: 'right ',
			55: 'left ',
			86: 'right ',
			87: 'left ',
			118: 'right ',
			570: 'f57 ',
			890: 'f89 ',
		};

		function typeIs(category) {
			var category = category || "";

			if(category.indexOf('gas') >= 0) {
				return 'gas';
			}else if(category.indexOf('halogens') >= 0) {
				return 'halogens';
			}else if(category.indexOf('other nonmetals') >= 0) {
				return 'otherNon';
			}else if(category.indexOf('metalloid') >= 0){
				return 'metalloid';
			}else if(category.indexOf('post-transition metal') >= 0){
				return 'postTrans';
			}else if(category.indexOf('alkaline') >= 0){
				return 'alkaline';
			}else if(category.indexOf('alkali') >= 0){
				return 'alkali';
			}else if(category.indexOf('lanthanide') >= 0){
				return 'lanthanide';
			}else if(category.indexOf('actinide') >= 0){
				return 'actinide';
			}else if(category.indexOf('transition metal') >= 0){
				return 'transMetal';
			}else {
				return '';
			}
		};

		$scope.filterByType = function($event, type) {
			$scope.sortByType = type;
			var el = $event.currentTarget;
			$(el).addClass('active');
		};		

		$scope.filterByTypeStop = function() {
			$scope.sortByType = "";

			if($scope.searchedElement == "" || typeof $scope.searchedElement == "undefined"){
				$(".types .metals ul li").each(function(){
					$(this).removeClass('active');
				});
				$(".types .nonMetals ul li").each(function(){
					$(this).removeClass('active');
				});			
			}
		};		

		$scope.filterByRow = function($event, row) {
			$scope.sortByRow = row;
		};		

		$scope.filterByRowStop = function() {
			$scope.sortByRow = "";
		};		

		$scope.filterByColumn = function($event, column) {
			$scope.sortByColumn = column;
		};		

		$scope.filterByColumnStop = function() {
			$scope.sortByColumn = "";
		};			

		$scope.place = function(el, category, xpos, ypos) {
			var classes = "";

			if($scope.searchedElement == "" || typeof $scope.searchedElement == "undefined"){
				$(".types").show();
				if(elPlaces[el] != "" && typeof elPlaces[el] != 'undefined' && elPlaces[el] != null) {
					var classes = elPlaces[el] + typeIs(category);
				}else {
					var classes = typeIs(category);
				}

				$scope.innerContainer = "";
			}else {
				$(".types").hide();
				var classes = typeIs(category);
				$scope.innerContainer = "active";
			}

			if($scope.sortByType != "" && typeof $scope.sortByType != "undefined"){

				if(elPlaces[el] != "" && typeof elPlaces[el] != 'undefined' && elPlaces[el] != null) {
					if($scope.sortByType == typeIs(category)) {
						var classes = elPlaces[el] + typeIs(category);
					}else {
						var classes = elPlaces[el] + "gray";
					}
				}else {
					if($scope.sortByType == typeIs(category)) {
						var classes = typeIs(category);
					}else {
						var classes = "gray";
					}
				}			

			}

			if($scope.sortByRow != "" && typeof $scope.sortByRow != "undefined"){
				if(xpos != "" && typeof xpos != "undefined"){
					if(xpos == $scope.sortByRow) {
						classes += " active";
					}else{
						classes += " gray";
					}			
				}	
			}

			if($scope.sortByColumn != "" && typeof $scope.sortByColumn != "undefined"){
				if(ypos != "" && typeof ypos != "undefined"){
					if(ypos == $scope.sortByColumn) {
						classes += " active";
					}else{
						classes += " gray";
					}			
				}	
			}			

			return classes;
		};

		$scope.changeElement = function(key, data, $event) {
			if ($scope.searchedElement == "" || typeof $scope.searchedElement == "undefined"){
				$(".element").each(function(){
					$(this).removeClass('highlighted');
				});

				$(".types ul li").each(function(){
					$(this).removeClass('active');
				});

				if(data.number == "570" || data.number == "890"){
					$scope.isVisible = false;
				}else{
					$scope.isVisible = true;
				}

				var el = $event.currentTarget;
				if(data.number != "570" && data.number != "890") {
					$(el).addClass('highlighted');
				}

				var divType = typeIs(data.category);
				if(data.category != "" && typeof data.category != 'undefined') {
					$(".types ul li." + divType).addClass('active');
				}

				$scope.elHoverName = key;
				$scope.elHoverData = data;
			}
		};			

		$scope.hideElement = function($event) {
			$scope.isVisible = false;

			var el = $event.currentTarget;
			$(el).removeClass('highlighted');

			$(".types ul li").each(function(){
				$(this).removeClass('active');
			});			
		};

		$scope.showElement = $scope.isVisible;

		$scope.$watch('isVisible', function(){
			if($scope.isVisible) {
				$scope.showElement = true;
			}else {
				$scope.showElement = false;
			}
		});

		//Open the popin - right to left
		$scope.openData = function(key, data) {
			$scope.elementName = key;
			$scope.moreData = data;

			$("aside").addClass('active');		

			myAtom.setNumElectrons(data.number);

			var orbitalRotationConfig = {
			  pattern: {
			    alternating: true,
			    clockwise: false,
			    preset: 'parabolaUp',
			  }
			}
			myAtom.rotateOrbitals(orbitalRotationConfig)			
		};

		//Hide the popin
		$scope.hideData = function() {
			$("aside").removeClass('active');
		};

		//Filter main elements by search input value
		$scope.filterEl = function(elements) {
		    var result = {};

		    if($scope.searchedElement != "" && typeof $scope.searchedElement != "undefined") {
			    angular.forEach(elements, function(value, key) {
			        if (value.symbol != "57-71" && value.symbol != "89-103" && key.toLowerCase().indexOf($scope.searchedElement) != -1 || value.symbol.toLowerCase().indexOf($scope.searchedElement) != -1) {
			            result[key] = value;
			        }
			    });

			    $scope.hasClasses = "0";
		    }else {
		    	result = elements;
		    }
		    return result;
		};		

		//Filter rest elements by search input value
		$scope.filterRestEl = function(elements) {
		    var result = {};

		    if($scope.searchedElement != "" && typeof $scope.searchedElement != "undefined") {
			    angular.forEach(elements, function(value, key) {
			        if (key.toLowerCase().indexOf($scope.searchedElement) != -1 || value.symbol.toLowerCase().indexOf($scope.searchedElement) != -1) {
			            result[key] = value;
			        }
			    });

			    $scope.hasClasses = "0";
		    }else {
		    	result = elements;
		    }
		    return result;
		};
	
		var myAtom = new Atom({
		  containerId: '#bohr-model',
		  numElectrons: 1,
		  nucleusRadius: 20, 
		  nucleusColor: 'rgba(3, 169, 244, 0.2)',
		  electronColor: 'rgba(24, 255, 255, 0.6)',
		  electronRadius: 2, 
		  orbitalSpacing: 10,
		  orbitalColor: 'rgba(24, 255, 255, 0.3)',
		  orbitalWidth: 0.5,
		  idNumber: 1, 
		  animationTime: 600,
		  rotateConfig: {speed: 50, clockwise: true},
		  orbitalRotationConfig: { 
		    pattern: {
		      alternating: true,
		      clockwise: false, 
		      preset: 'linearPositive', 
		    }
		  },
		  symbolOffset: 7,
		  drawSymbol: true 
		});		

});
